import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Button } from "@material-ui/core";
// core components
import { headerStyle } from "assets/jss/material-kit-pro-react/components";
import { Facebook } from '@material-ui/icons';
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(headerStyle);

export default function Header(props) {
  const classes = useStyles();
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;

    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  if (useMediaQuery('(max-width:993px)')) {
    const Menusettings1 = {
      style: {
        fontSize: "26px"
      }
    };

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <Button className={classes.title}>
          <Link to="/"><span className="icon-home4" {...Menusettings1}></span></Link>
        </Button>
        <Button className={classes.title}>
          <Link to="/Menu"><span className="icon-dinner" {...Menusettings1}></span></Link>
        </Button>
        <Button className={classes.title}>
        <Link to="/ContactUs"><span className="icon-map" {...Menusettings1}></span></Link>
        </Button>        
        <Button className={classes.title}>
        <a href="https://www.facebook.com/mexpozolerestaurant/" target="_blank" rel="noopener noreferrer">
          <Facebook
            style={{
             fontSize: "34px"
           }}>
             </Facebook></a>
        </Button>
      </Toolbar>
    </AppBar>
  );
} 

  else{
    const Menusettings = {
      style: {
        marginRight: "6px"
      }
    };

    return (
      <AppBar className={appBarClasses}>
        <Toolbar className={classes.container}>
          <Button className={classes.title}>
            <Link to="/"><span className="icon-home4" {...Menusettings}></span>Home</Link>
          </Button>
          <Button className={classes.title}>
            <Link to="/Menu"><span className="icon-dinner" {...Menusettings}></span>Menu</Link>
          </Button>
          <Button className={classes.title}>
          <Link to="/ContactUs"><span className="icon-map" {...Menusettings}></span>Contact Us</Link>
          </Button>        
          <Button className={classes.title}>
          <a href="https://www.facebook.com/mexpozolerestaurant/" target="_blank" rel="noopener noreferrer">
          <Facebook
            style={{
             fontSize: "34px"
           }}>
             </Facebook></a>
          </Button>
        </Toolbar>
      </AppBar>
    );
  }}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
