import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import { Fondo2 } from "assets/img/index";
import { useMediaQuery } from "@material-ui/core";
import sectionImageStyle from "assets/jss/material-kit-pro-react/views/sectionImageStyle.js";

const useStyles = makeStyles(sectionImageStyle);

export default function SectionImage() {
  const classes = useStyles();

  if (useMediaQuery('(max-width:993px)')) {
    return (
    <div
    className={classes.section}
    style={{ backgroundPosition: "inherit", backgroundImage: "url(" + Fondo2 + ")" }}
  >
   <h2 style={{color: "#fff", fontFamily: `"Roboto Slab", "Times New Roman", serif`, textAlign:"center", fontSize:"46px"}}><b> Appetizers</b></h2>
  <br/>
  </div>
);}


  else{
  return (
    <div
      className={classes.section}
      style={{ padding: "150px 0", backgroundPosition: "inherit", backgroundImage: "url(" + Fondo2 + ")" }}
    >
     <h2 style={{color: "#fff", fontFamily: `"Roboto Slab", "Times New Roman", serif`, textAlign:"center", fontSize:"46px"}}><b> Appetizers</b></h2>
    <br/>
    </div>
  );}
}
