/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { AccessTime, PinDrop, Phone} from "@material-ui/icons";
import { Header, Footer, GridContainer, InfoArea, GridItem, HeaderLinks } from "components";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";

const CustomSkinMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 29.878136, lng: -95.724633 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 29.878136, lng: -95.724633 }} /> 
    </GoogleMap>
  ))
);

const useStyles = makeStyles(contactUsStyle);

export default function ContactUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        fixed
        color="info"
      />
      <div className={classes.bigMap}>
        <CustomSkinMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmDu0h3qmK0X50pZMOzgOenG8J9TeqRLg"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              style={{
                height: `100%`,
                borderRadius: "6px",
                overflow: "hidden"
              }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
      <div className={classNames(classes.main, classes.mainRaised)} style={{color: "#999", backgroundColor: "#282828"}}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <h3 style={{color: "#fff", fontFamily: `"Roboto Slab", "Times New Roman", serif`}}> About Us </h3>
            <br/>
            <p>
            Our mission is to give our customers an exquisite homely experience 
            through our values and typical Mexican gastronomy.{" "}
            </p>
            <br/>
            <p>We invite you to enjoy our restaurant's tremendously delicious recipes made from scratch 
                   every day especially for you. Enjoy a delicious breakfast, lunch or dinner at our place.
            </p>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <h3 style={{color: "#fff", fontFamily: `"Roboto Slab", "Times New Roman", serif`}}>Contact Details</h3>
            <br/>
            <div className={classes.socialFeed}>
            <InfoArea
                  className={classes.info}
                  title="Address"
                  description={
                    <p>
                     6871 N. Fry Rd. Suite 112 <br /> Katy, Texas 77449
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="primary"
                />
                <InfoArea
                      className={classes.infoArea2}
                      title="Give us a ring"
                      description={
                        <span>
                          (832) 835 7584 <br />
                        </span>
                      }
                      icon={Phone}
                      iconColor="primary"
                    />
                <InfoArea
                  className={classes.info}
                  title="Hours"
                  description={
                    <p>
                      <span>MON </span> 08:00 – 20:00<br/>
                      <span>TUE </span> 08:00 – 20:00<br/>
                      <span>WED </span> 08:00 – 20:00<br/>
                      <span>THU </span> 08:00 – 20:00<br/>
                      <span>FRI </span> 08:00 – 20:30<br/>
                      <span>SAT </span> 08:00 – 20:30<br/>
                      <span>SUN </span> 08:00 – 20:00  
                    </p>
                  }
                  icon={AccessTime}
                  iconColor="primary"
                />
            </div>
          </GridItem>
        </GridContainer>
          </div>
        </div>
      </div>
      <Footer
       content={
         <div>
           <div className={classes.right}>
           Mexpozole Restaurant – Copyright &copy; {1900 + new Date().getYear()}{" "}
           </div>
         </div>
       }
     />
    </div>
  );
}
