/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-slick";
import { Header, Footer, GridContainer, InfoArea, GridItem, HeaderLinks, Card, CardBody, CardHeader, Parallax1 } from "components";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import { Burrito, CharroBeans, Cheese_cake, Chicken_Nuggets, Chilaquiles_Mananeros, Choriqueso, Churros, Enchiladas, 
  Entomatadas_Con_Cecina, Flan_Napolitano, Flautas, Gordita, Guacamole, Huevos_al_gusto, Huevos_Divorciados, 
  Huevos_Rancheros, Kids_Breakfast, Menudo, Molcajete, Molletes, Nachos, Orden_De_Quesadillas, Orden_De_Sopes, Orden_De_Tacos, 
  Pollo_a_la_Plancha, Pollo_ala_Plancha, Pozole_Rojo, Pozole_Verde, Quesadilla_Grande, Tacos_mananeros, 
  Tampiquena, Tortas, Tres_Leches } from "assets/img/index";
  import SectionImage from "./SectionImage";
  import SectionImage2 from "./SectionImage2";
  import SectionImage4 from "./SectionImage4";    
  import { useMediaQuery } from "@material-ui/core";
const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const settings1 = {
    dots: false,
    infinite: false,
    speed: 0,
    slidesToShow: 2,
    slidesToScroll: 0,
    autoplay: false,
    cancelable: false
  };
  if (useMediaQuery('(max-width:993px)')) {
    return (
      <div>
       <Header
          fixed
          color="info"
        />
        <Parallax1 image={require("assets/img/Fondo_Desayuno.jpg")} filter="dark" small>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h1 className={classes.title}>Breakfast</h1>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax1>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
          <GridContainer justify="center">      
             <GridItem xs={12} sm={10} md={10}> 
             <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Kids_Breakfast} alt="Kids_Breakfast" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Kids_Breakfast + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    Pancakes
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Pancakes, served with egg and bacon.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Chilaquiles_Mananeros} alt="Chilaquiles_Mañaneros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Chilaquiles_Mananeros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHILAQUILES MAÑANEROS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Fried tortilla chips, sauteed with your choice of green or red sauce, 
                    topped wiht fresh cheese and sour cream, and two fried eggs. Comes with fried beans.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Chicken_Nuggets} alt="Chicken_Nuggets" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Chicken_Nuggets + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHICKEN NUGGETS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Served with fries
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_Divorciados} alt="Huevos_Divorciados" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_Divorciados + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS DIVORCIADOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Plate with two fried eggs over fried corn tortillas, covered with red and green salsa. 
                    Comes with a side of beans, chopped potatoes and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_al_gusto} alt="Huevos_al_gusto" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_al_gusto + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS AL GUSTO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Two escrambled eggs mix – with your choice of chorizo, potato, bacon, ham, “A la Mexicana”. 
                    Comes with a side of beans and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Molletes} alt="Molletes" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Molletes + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MOLLETES
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    French bread topped with butter, fried beans, melted cheese and pico de gallo.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_Rancheros} alt="Huevos_Rancheros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_Rancheros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS RANCHEROS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Plate with two fried eggs over fried corn tortilla covered with salsa ranchera. 
                    Comes with a side of beans, chopped potatoes and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tacos_mananeros} alt="Tacos_mañaneros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tacos_mananeros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TACOS MAÑANEROS(2)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    (Corn or flour tourtilla)<br/>
                     Two breakfast tacos of your choice: 
                     Egg & chorizo, Egg & potato, Egg & bacon, Egg & ham, Egg “A la mexicana”
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             </GridItem>
          </GridContainer>
          </div> 
          </div>

          <br/><br/>
          <SectionImage />
         <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
          <GridContainer justify="center">      
             <GridItem xs={12} sm={10} md={10}> 
             <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Nachos} alt="Nachos" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Nachos + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    NACHOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Fried corn tortilla chips or totopos covered with cheddar cheese sauce, 
                    topped with guacamole, pico de gallo and sour cream and your choice of beef fajita or chicken.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={CharroBeans} alt="Charro_Beans" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + CharroBeans + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHARRO BEANS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican dish, ingredients include beans, onion, 
                    chili peppers, tomatoes, cilantro, ham, sausage, bacon and chorizo. It is served warm.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Choriqueso} alt="Choriqueso" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Choriqueso + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHORIQUESO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Melted white cheese with chorizo. Served with corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Guacamole} alt="Guacamole" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Guacamole + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    GUACAMOLE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Prices according to the market
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             </GridItem>
          </GridContainer>
          </div> 
          </div>







          <br/><br/>
          <SectionImage2 />
         <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
          <GridContainer justify="center">      
             <GridItem xs={12} sm={10} md={10}> 
             <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tortas} alt="Tortas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tortas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TORTAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Mexican sandwich in a bolillo bread, topped with refried beans, beans, lettuce, 
                    tomato, and sour cream, and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibl<br/>
                    Chorizo<br/>
                    Carnitas<br/>
                    Cubana (comes with pork meat, ham and<br/>
                    mozzarella cheese)
                    </p>
                    <p><br/></p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Quesadilla_Grande} alt="Quesadilla_Grande" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Quesadilla_Grande + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    QUESADILLA GRANDE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Comes with refriend beans, rice, pico de gallo, and lettuce on the side. 
                    Large flour tortilla filled with mozzarella cheese and meat of your choice:<br/>                    
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajta<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Burrito} alt="Burrito " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Burrito + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    BURRITO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Large flour tortilla filled with refried beans, 
                    rice, lettuce, tomato, fresh cheese, sour cream, and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pozole_Rojo} alt="Pozole_Rojo " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pozole_Rojo + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POZOLE ROJO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican red Soup with pork meat and hominy. 
                    Garnished with shredded lettuce, radish and crispy pork skin on a side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pozole_Verde} alt="Pozole_Verde" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pozole_Verde + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POZOLE VERDE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican Green Soup with chicken meat and hominy. 
                    Garnished with shredded lettuce, radish and crispy pork skin on a side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Menudo} alt="Menudo" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Menudo + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MENUDO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican soup made with cow’s stomach. Also known as “Pancita”, 
                    Served with flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pollo_a_la_Plancha } alt="TACOS_POTOSINOS" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pollo_a_la_Plancha + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TACOS POTOSINOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Soft fried corn tortilla with red sauce, filled with fresh cheese, salad, and beef fajita on the side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tampiquena } alt="Tampiqueña " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tampiquena  + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TAMPIQUEÑA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Strip of the roasted beef over grilled onions and bell pepper. 
                    Served with a red enchilada filled with white cheese, black beans, 
                    rice, pico de gallo and your choice of flour or homemade corn tortillas
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Enchiladas} alt="Enchiladas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Enchiladas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ENCHILADAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Soft corn tortillas filled with chicken, 
                    topped with mole sauce and sesame seeds. 
                    Served with a side of fried beans and rice.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Flautas} alt="Flautas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Flautas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    FLAUTAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Rolled fried tacos with your choice of chicken or beef, topped with 
                    lettuce, tomato, fresh cheese and sour cream. Served with a side of fried beans and rice.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pollo_ala_Plancha} alt="Pollo_ala_Plancha" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pollo_ala_Plancha + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POLLO A LA PLANCHA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Grilled chicken breast served with lettuce, tomatoes, pico de gallo, bell pepper, 
                    rice and charros beans. Served with flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Quesadillas} alt="Orden_De_Quesadillas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Quesadillas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE QUESADILLAS (2)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Corn or flour tortillas with mozzarella cheese and meat of your choice:
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajta<br/>
                    Cochinita pibil,<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Sopes} alt="Orden_De_Sopes" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Sopes + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE SOPES (3)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Thick homemade corn tortilla, slightly fried, 
                    topped with black beans, lettuce, tomato, fresh cheese, sour cream, and meat of your choice:
                    Trompo<br/>
                     Beef fajita<br/>
                     Chicken fajta<br/>
                     Cochinita pibil<br/>
                     Chorizo<br/>
                     Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Tacos} alt="Orden_De_Tacos " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Tacos + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE TACOS (4)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Four corn tortillas topped with grilled onion, cilantro, pickled carrots on the side, 
                    and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Gordita} alt="Gordita " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Gordita + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    GORDITA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Thick homemade tortilla filled with mozzarella cheese and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Molcajete} alt="Molcajete" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Molcajete + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MOLCAJETE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Grilled beef and chicken fajitas, and mexican sausage served with grilled onion, mexican cheese, 
                    cactus and bell pepper in a stone bowl. 
                    Comes with charro beans, rice and pico de gallo on the side. Includes flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Entomatadas_Con_Cecina} alt="Entomatadas_Con_Cecina" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Entomatadas_Con_Cecina + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ENTOMATADAS CON CECINA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Three homemade tortillas dressed in ranchera sauce, 
                    topped with fresh cheese, served with black beans and a side of cecina.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             </GridItem>
          </GridContainer>
          </div> 
          </div>






          <br/><br/>
          <SectionImage4 />
         <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
          <GridContainer justify="center">      
             <GridItem xs={12} sm={10} md={10}> 
             <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tres_Leches} alt="Tres_Leches" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tres_Leches + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TRES LECHES
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Flan_Napolitano} alt="Flan_Napolitano" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Flan_Napolitano + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    FLAN NAPOLITANO
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Churros} alt="Churros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Churros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHURROS
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Cheese_cake} alt="Cheese_cake" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Cheese_cake + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHEESE CAKE
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
             </GridItem>
          </GridContainer>
          </div> 
          </div>
          <Footer
         content={
         <div>
           <div className={classes.right}>
           Mexpozole Restaurant – Copyright &copy; {1900 + new Date().getYear()}{" "}
           </div>
         </div>
       }
     />
          </div>      
  );}








  else{
  return (
    <div>
     <Header
        fixed
        color="info"
      />
      <Parallax1 image={require("assets/img/Fondo_Desayuno.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Breakfast</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax1>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
        <GridContainer justify="center">      
             <GridItem xs={12} sm={12} md={12}>          
               <Carousel {...settings1} >
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Kids_Breakfast} alt="Kids_Breakfast" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Kids_Breakfast + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    Pancakes
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Pancakes, served with egg and bacon.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Chilaquiles_Mananeros} alt="Chilaquiles_Mañaneros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Chilaquiles_Mananeros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHILAQUILES MAÑANEROS 
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Fried tortilla chips, sauteed with your choice of green or red sauce, 
                    topped wiht fresh cheese and sour cream, and two fried eggs. Comes with fried beans.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Chicken_Nuggets} alt="Chicken_Nuggets" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Chicken_Nuggets + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHICKEN NUGGETS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Served with fries
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_Divorciados} alt="Huevos_Divorciados" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_Divorciados + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS DIVORCIADOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Plate with two fried eggs over fried corn tortillas, covered with red and green salsa. 
                    Comes with a side of beans, chopped potatoes and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             </Carousel>
             </GridItem>
              <GridItem xs={12} sm={12} md={12}>          
               <Carousel {...settings1} >
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_al_gusto} alt="Huevos_al_gusto" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_al_gusto + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS AL GUSTO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Two escrambled eggs mix – with your choice of chorizo, potato, bacon, ham, “A la Mexicana”. 
                    Comes with a side of beans and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Molletes} alt="Molletes" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Molletes + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MOLLETES
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    French bread topped with butter, fried beans, melted cheese and pico de gallo.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             </Carousel>
             </GridItem>
              <GridItem xs={12} sm={12} md={12}>          
               <Carousel {...settings1} >
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_Rancheros} alt="Huevos_Rancheros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_Rancheros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS RANCHEROS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Plate with two fried eggs over fried corn tortilla covered with salsa ranchera. 
                    Comes with a side of beans, chopped potatoes and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tacos_mananeros} alt="Tacos_mañaneros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tacos_mananeros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TACOS MAÑANEROS(2)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    (Corn or flour tourtilla)<br/>
                     Two breakfast tacos of your choice: 
                     Egg & chorizo, Egg & potato, Egg & bacon, Egg & ham, Egg “A la mexicana”
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             </Carousel>
             </GridItem>    

       </GridContainer>
        </div>
      </div>



      <br/><br/>
      <SectionImage />
      <br/>


      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
        <GridContainer justify="center">    
              <GridItem xs={12} sm={12} md={12}>          
               <Carousel {...settings1} > 
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Nachos} alt="Nachos" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Nachos + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    NACHOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Fried corn tortilla chips or totopos covered with cheddar cheese sauce, 
                    topped with guacamole, pico de gallo and sour cream and your choice of beef fajita or chicken.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={CharroBeans} alt="Charro_Beans" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + CharroBeans + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHARRO BEANS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican dish, ingredients include beans, onion, 
                    chili peppers, tomatoes, cilantro, ham, sausage, bacon and chorizo. It is served warm.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  </Carousel> 
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>          
                  <Carousel {...settings1} > 
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Choriqueso} alt="Choriqueso" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Choriqueso + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHORIQUESO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Melted white cheese with chorizo. Served with corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Guacamole} alt="Guacamole" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Guacamole + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    GUACAMOLE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Prices according to the market
                    </p>
                  </CardBody>
                  </Card>
                  </div>
              </Carousel>
              </GridItem> 
         </GridContainer>
         </div>
         </div>

      

         <br/><br/>
      <SectionImage2 />
      <br/>


      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
        <GridContainer justify="center">    
              <GridItem xs={12} sm={12} md={12}>          
               <Carousel {...settings1} > 
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tortas} alt="Tortas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tortas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TORTAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Mexican sandwich in a bolillo bread, topped with refried beans, beans, lettuce, 
                    tomato, and sour cream, and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibl<br/>
                    Chorizo<br/>
                    Carnitas<br/>
                    Cubana (comes with pork meat, ham and<br/>
                    mozzarella cheese)
                    </p>
                    <p><br/></p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Quesadilla_Grande} alt="Quesadilla_Grande" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Quesadilla_Grande + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    QUESADILLA GRANDE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Comes with refriend beans, rice, pico de gallo, and lettuce on the side. 
                    Large flour tortilla filled with mozzarella cheese and meat of your choice:<br/>                    
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajta<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  </Carousel> 
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>          
                  <Carousel {...settings1} > 
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Burrito} alt="Burrito " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Burrito + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    BURRITO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Large flour tortilla filled with refried beans, 
                    rice, lettuce, tomato, fresh cheese, sour cream, and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pozole_Rojo} alt="Pozole_Rojo " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pozole_Rojo + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POZOLE ROJO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican red Soup with pork meat and hominy. 
                    Garnished with shredded lettuce, radish and crispy pork skin on a side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
              </Carousel>
              </GridItem>
              
              
              
              <GridItem xs={12} sm={12} md={12}>          
                  <Carousel {...settings1} > 
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pozole_Verde} alt="Pozole_Verde" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pozole_Verde + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POZOLE VERDE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican Green Soup with chicken meat and hominy. 
                    Garnished with shredded lettuce, radish and crispy pork skin on a side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Menudo} alt="Menudo" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Menudo + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MENUDO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican soup made with cow’s stomach. Also known as “Pancita”, 
                    Served with flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
              </Carousel>
              </GridItem> 

              <GridItem xs={12} sm={12} md={12}>          
                  <Carousel {...settings1} > 
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pollo_a_la_Plancha } alt="TACOS_POTOSINOS" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pollo_a_la_Plancha + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TACOS POTOSINOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Soft fried corn tortilla with red sauce, filled with fresh cheese, salad, and beef fajita on the side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tampiquena } alt="Tampiqueña " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tampiquena  + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TAMPIQUEÑA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Strip of the roasted beef over grilled onions and bell pepper. 
                    Served with a red enchilada filled with white cheese, black beans, 
                    rice, pico de gallo and your choice of flour or homemade corn tortillas
                    </p>
                  </CardBody>
                  </Card>
                  </div>
              </Carousel>
              </GridItem>


                            <GridItem xs={12} sm={12} md={12}>          
                  <Carousel {...settings1} > 
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Enchiladas} alt="Enchiladas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Enchiladas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ENCHILADAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Soft corn tortillas filled with chicken, 
                    topped with mole sauce and sesame seeds. 
                    Served with a side of fried beans and rice.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Flautas} alt="Flautas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Flautas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    FLAUTAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Rolled fried tacos with your choice of chicken or beef, topped with 
                    lettuce, tomato, fresh cheese and sour cream. Served with a side of fried beans and rice.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
              </Carousel>
              </GridItem>  


                            <GridItem xs={12} sm={12} md={12}>          
                  <Carousel {...settings1} > 
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pollo_ala_Plancha} alt="Pollo_ala_Plancha" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pollo_ala_Plancha + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POLLO A LA PLANCHA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Grilled chicken breast served with lettuce, tomatoes, pico de gallo, bell pepper, 
                    rice and charros beans. Served with flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Quesadillas} alt="Orden_De_Quesadillas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Quesadillas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE QUESADILLAS (2)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Corn or flour tortillas with mozzarella cheese and meat of your choice:
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajta<br/>
                    Cochinita pibil,<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
              </Carousel>
              </GridItem>  

                            <GridItem xs={12} sm={12} md={12}>          
                  <Carousel {...settings1} > 
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Sopes} alt="Orden_De_Sopes" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Sopes + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE SOPES (3)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Thick homemade corn tortilla, slightly fried, 
                    topped with black beans, lettuce, tomato, fresh cheese, sour cream, and meat of your choice:
                    Trompo<br/>
                     Beef fajita<br/>
                     Chicken fajta<br/>
                     Cochinita pibil<br/>
                     Chorizo<br/>
                     Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Tacos} alt="Orden_De_Tacos " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Tacos + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE TACOS (4)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Four corn tortillas topped with grilled onion, cilantro, pickled carrots on the side, 
                    and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
              </Carousel>
              </GridItem>  

                            <GridItem xs={12} sm={12} md={12}>          
                  <Carousel {...settings1} > 
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Gordita} alt="Gordita " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Gordita + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    GORDITA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Thick homemade tortilla filled with mozzarella cheese and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Molcajete} alt="Molcajete" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Molcajete + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MOLCAJETE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Grilled beef and chicken fajitas, and mexican sausage served with grilled onion, mexican cheese, 
                    cactus and bell pepper in a stone bowl. 
                    Comes with charro beans, rice and pico de gallo on the side. Includes flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
              </Carousel>
              </GridItem> 


                                          <GridItem xs={12} sm={12} md={12}>          
                  <Carousel {...settings1} > 
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Entomatadas_Con_Cecina} alt="Entomatadas_Con_Cecina" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Entomatadas_Con_Cecina + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ENTOMATADAS CON CECINA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Three homemade tortillas dressed in ranchera sauce, 
                    topped with fresh cheese, served with black beans and a side of cecina.
                    </p>
                  </CardBody>
                  </Card>
                  </div>

              </Carousel>
              </GridItem>     
         </GridContainer>
         </div>
         </div>






         <br/><br/>
      <SectionImage4 />
      <br/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
        <GridContainer justify="center">    
              <GridItem xs={12} sm={12} md={12}>          
               <Carousel {...settings1} > 
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tres_Leches} alt="Tres_Leches" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tres_Leches + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TRES LECHES
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Cheese_cake} alt="Cheese_cake" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Cheese_cake + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHEESE CAKE
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
                  </Carousel> 
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>          
                  <Carousel {...settings1} > 
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Churros} alt="Churros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Churros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHURROS
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Flan_Napolitano} alt="Flan_Napolitano" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Flan_Napolitano + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    FLAN NAPOLITANO
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
              </Carousel>
              </GridItem>
         </GridContainer>
         </div>
         </div>
      <Footer
       content={
         <div>
           <div className={classes.right}>
           Mexpozole Restaurant – Copyright &copy; {1900 + new Date().getYear()}{" "}
           </div>
         </div>
       }
     />
    </div>
  );}
}
