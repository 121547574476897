import React from "react";
import classNames from "classnames";
import Carousel from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import { AccessTime, PinDrop, Phone } from "@material-ui/icons";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { Header, Footer, GridContainer, InfoArea, GridItem, HeaderLinks, Card, CardBody, CardHeader, Parallax } from "components";
import { useMediaQuery } from "@material-ui/core";

import { Burrito, CharroBeans, Cheese_cake, Chicken_Nuggets, Chilaquiles_Mananeros, Choriqueso, Churros, Enchiladas, 
  Entomatadas_Con_Cecina, Flan_Napolitano, Flautas, Gordita, Guacamole, Huevos_al_gusto, Huevos_Divorciados, 
  Huevos_Rancheros, Kids_Breakfast, Menudo, Molcajete, Molletes, Nachos, Orden_De_Quesadillas, Orden_De_Sopes, Orden_De_Tacos, 
  Pollo_a_la_Plancha, Pollo_ala_Plancha, Pozole_Rojo, Pozole_Verde, Quesadilla_Grande, Tacos_mananeros, Tampiquena, Tortas, 
  Tres_Leches, Textura, Textura2 } from "assets/img/index";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";


const useStyles = makeStyles(profilePageStyle);

export default function ProfilePage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const CustomSkinMap = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={14} 
        defaultCenter={{ lat: 29.878136, lng: -95.724633 }}
        defaultOptions={{
          scrollwheel: false,
          zoomControl: true,
          styles: [
            {
              featureType: "water",
              stylers: [
                { saturation: 43 },
                { lightness: -11 },
                { hue: "#0088ff" }
              ]
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                { hue: "#ff0000" },
                { saturation: -100 },
                { lightness: 99 }
              ]
            },
            {
              featureType: "road",
              elementType: "geometry.stroke",
              stylers: [{ color: "#808080" }, { lightness: 54 }]
            },
            {
              featureType: "landscape.man_made",
              elementType: "geometry.fill",
              stylers: [{ color: "#ece2d9" }]
            },
            {
              featureType: "poi.park",
              elementType: "geometry.fill",
              stylers: [{ color: "#ccdca1" }]
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [{ color: "#767676" }]
            },
            {
              featureType: "road",
              elementType: "labels.text.stroke",
              stylers: [{ color: "#ffffff" }]
            },
            { featureType: "poi", stylers: [{ visibility: "off" }] },
            {
              featureType: "landscape.natural",
              elementType: "geometry.fill",
              stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
            },
            { featureType: "poi.park", stylers: [{ visibility: "on" }] },
            {
              featureType: "poi.sports_complex",
              stylers: [{ visibility: "on" }]
            },
            { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
            {
              featureType: "poi.business",
              stylers: [{ visibility: "simplified" }]
            }
          ]
        }}
      >
        <Marker position={{ lat: 29.878136, lng: -95.724633 }} />
      </GoogleMap>
    ))
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  const TexturStyle = {
    style:{
      overflow: "hidden",
      position: "relative",
      backgroundImage: "url(" + Textura + ")",
      backgroundRepeat: "repeat",
      margin: "0",
      padding: "0",
      border: "0",
      display: "flex",
      alignItems: "center",
   }};

   const TexturStyle2 = {
    style:{
      overflow: "hidden",
      position: "relative",
      backgroundImage: "url(" + Textura2 + ")",
      backgroundRepeat: "repeat",
      margin: "0",
      padding: "0",
      border: "0",
      display: "flex",
      alignItems: "center",
   }};
  
if (useMediaQuery('(max-width:993px)')) {
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    cancelable: false
  };
   return(
    <div>
    <Header
       color="transparent"
       fixed
       changeColorOnScroll={{
         height: 200,
         color: "info"
       }}
       {...rest}
     />
 
     <Parallax
       className={classes.parallax}
     />
     <div className={classNames(classes.main, classes.mainRaised)}>
       <div className={classes.container}>
         <GridContainer justify="center">
           <GridItem xs={12} sm={12} md={12}>
           <div className={classes.section} id="carousel">
     <div className={classes.container}>
       <GridContainer justify="center">
       <GridItem xs={12} sm={11} md={11}>
           <Card>
             <Carousel {...settings}>
               <div>
                 <img src={Pozole_Rojo} alt="Pozole_Rojo" className="slick-image" />
               </div>
               <div>
                 <img src={Molcajete} alt="Molcajete" className="slick-image" />
               </div>
               <div>
                 <img src={Enchiladas} alt="Enchiladas" className="slick-image" />
               </div>
             </Carousel>
           </Card>
          </GridItem> 
       </GridContainer>
       <h3 className={classes.title + " " + classes.textCenter}>WELCOME TO MEXPOZOLE RESTAURANT</h3>
     </div>
   </div>    
           </GridItem>
         </GridContainer>
         
       </div>
     </div>
     <div className={classes.section}>
     <div className={classes.container}>
     <GridContainer justify="center">      
             <GridItem xs={12} sm={8} md={8}> 
             <h2 className={classes.title + " " + classes.textCenter}>
            <br/> Breakfast <span className="icon-fingers-scroll-horizontal"></span>
           </h2>            
               <Carousel {...settings1} {...TexturStyle}>
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Kids_Breakfast} alt="Kids_Breakfast" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Kids_Breakfast + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    Pancakes
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Pancakes, served with egg and bacon.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Chilaquiles_Mananeros} alt="Chilaquiles_Mañaneros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Chilaquiles_Mananeros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHILAQUILES MAÑANEROS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Fried tortilla chips, sauteed with your choice of green or red sauce, 
                    topped wiht fresh cheese and sour cream, and two fried eggs. Comes with fried beans.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_Divorciados} alt="Huevos_Divorciados" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_Divorciados + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS DIVORCIADOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Plate with two fried eggs over fried corn tortillas, covered with red and green salsa. 
                    Comes with a side of beans, chopped potatoes and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Chicken_Nuggets} alt="Chicken_Nuggets" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Chicken_Nuggets + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHICKEN NUGGETS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Served with fries
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_al_gusto} alt="Huevos_al_gusto" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_al_gusto + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS AL GUSTO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Two escrambled eggs mix – with your choice of chorizo, potato, bacon, ham, “A la Mexicana”. 
                    Comes with a side of beans and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Molletes} alt="Molletes" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Molletes + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MOLLETES 
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    French bread topped with butter, fried beans, melted cheese and pico de gallo.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_Rancheros} alt="Huevos_Rancheros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_Rancheros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS RANCHEROS 
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Plate with two fried eggs over fried corn tortilla covered with salsa ranchera. 
                    Comes with a side of beans, chopped potatoes and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tacos_mananeros} alt="Tacos_mañaneros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tacos_mananeros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TACOS MAÑANEROS(2)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    (Corn or flour tourtilla)<br/>
                     Two breakfast tacos of your choice: 
                     Egg & chorizo, Egg & potato, Egg & bacon, Egg & ham, Egg “A la mexicana”
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             </Carousel>
             </GridItem>             
       </GridContainer>

       <GridContainer justify="center">      
             <GridItem xs={12} sm={8} md={8}>  
             <h2 className={classes.title + " " + classes.textCenter}>
             Appetizers <span className="icon-fingers-scroll-horizontal"></span>
           </h2>            
               <Carousel {...settings1} {...TexturStyle2}>
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Nachos} alt="Nachos" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Nachos + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    NACHOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Fried corn tortilla chips or totopos covered with cheddar cheese sauce, 
                    topped with guacamole, pico de gallo and sour cream and your choice of beef fajita or chicken.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={CharroBeans} alt="Charro_Beans" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + CharroBeans + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHARRO BEANS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican dish, ingredients include beans, onion, 
                    chili peppers, tomatoes, cilantro, ham, sausage, bacon and chorizo. It is served warm.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Choriqueso} alt="Choriqueso" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Choriqueso + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHORIQUESO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Melted white cheese with chorizo. Served with corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Guacamole} alt="Guacamole" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Guacamole + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    GUACAMOLE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Prices according to the market
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             </Carousel>
             </GridItem>             
       </GridContainer>





       <GridContainer justify="center">      
             <GridItem xs={12} sm={8} md={8}>
             <h2 className={classes.title + " " + classes.textCenter}>
            <br/> Entrees <span className="icon-fingers-scroll-horizontal"></span>
           </h2>            
               <Carousel {...settings1} {...TexturStyle}>
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tortas} alt="Tortas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tortas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TORTAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Mexican sandwich in a bolillo bread, topped with refried beans, beans, lettuce, 
                    tomato, and sour cream, and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibl<br/>
                    Chorizo<br/>
                    Carnitas<br/>
                    Cubana (comes with pork meat, ham and<br/>
                    mozzarella cheese)
                    </p>
                    <p><br/></p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Quesadilla_Grande} alt="Quesadilla_Grande" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Quesadilla_Grande + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    QUESADILLA GRANDE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Comes with refriend beans, rice, pico de gallo, and lettuce on the side. 
                    Large flour tortilla filled with mozzarella cheese and meat of your choice:<br/>                    
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajta<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Burrito} alt="Burrito " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Burrito + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    BURRITO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Large flour tortilla filled with refried beans, 
                    rice, lettuce, tomato, fresh cheese, sour cream, and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pozole_Rojo} alt="Pozole_Rojo " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pozole_Rojo + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POZOLE ROJO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican red Soup with pork meat and hominy. 
                    Garnished with shredded lettuce, radish and crispy pork skin on a side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pozole_Verde} alt="Pozole_Verde" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pozole_Verde + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POZOLE VERDE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican Green Soup with chicken meat and hominy. 
                    Garnished with shredded lettuce, radish and crispy pork skin on a side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Menudo} alt="Menudo" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Menudo + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MENUDO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican soup made with cow’s stomach. Also known as “Pancita”, 
                    Served with flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pollo_a_la_Plancha } alt="TACOS_POTOSINOS" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pollo_a_la_Plancha + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TACOS POTOSINOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Soft fried corn tortilla with red sauce, filled with fresh cheese, salad, and beef fajita on the side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tampiquena } alt="Tampiqueña " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tampiquena  + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TAMPIQUEÑA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Strip of the roasted beef over grilled onions and bell pepper. 
                    Served with a red enchilada filled with white cheese, black beans, 
                    rice, pico de gallo and your choice of flour or homemade corn tortillas
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Enchiladas} alt="Enchiladas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Enchiladas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ENCHILADAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Soft corn tortillas filled with chicken, 
                    topped with mole sauce and sesame seeds. 
                    Served with a side of fried beans and rice.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Flautas} alt="Flautas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Flautas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    FLAUTAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Rolled fried tacos with your choice of chicken or beef, topped with 
                    lettuce, tomato, fresh cheese and sour cream. Served with a side of fried beans and rice.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pollo_ala_Plancha} alt="Pollo_ala_Plancha" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pollo_ala_Plancha + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POLLO A LA PLANCHA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Grilled chicken breast served with lettuce, tomatoes, pico de gallo, bell pepper, 
                    rice and charros beans. Served with flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Quesadillas} alt="Orden_De_Quesadillas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Quesadillas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE QUESADILLAS (2)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Corn or flour tortillas with mozzarella cheese and meat of your choice:
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajta<br/>
                    Cochinita pibil,<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Sopes} alt="Orden_De_Sopes" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Sopes + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE SOPES (3)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Thick homemade corn tortilla, slightly fried, 
                    topped with black beans, lettuce, tomato, fresh cheese, sour cream, and meat of your choice:
                    Trompo<br/>
                     Beef fajita<br/>
                     Chicken fajta<br/>
                     Cochinita pibil<br/>
                     Chorizo<br/>
                     Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Tacos} alt="Orden_De_Tacos " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Tacos + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE TACOS (4)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Four corn tortillas topped with grilled onion, cilantro, pickled carrots on the side, 
                    and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Gordita} alt="Gordita " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Gordita + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    GORDITA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Thick homemade tortilla filled with mozzarella cheese and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Molcajete} alt="Molcajete" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Molcajete + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MOLCAJETE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Grilled beef and chicken fajitas, and mexican sausage served with grilled onion, mexican cheese, 
                    cactus and bell pepper in a stone bowl. 
                    Comes with charro beans, rice and pico de gallo on the side. Includes flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Entomatadas_Con_Cecina} alt="Entomatadas_Con_Cecina" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Entomatadas_Con_Cecina + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ENTOMATADAS CON CECINA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Three homemade tortillas dressed in ranchera sauce, 
                    topped with fresh cheese, served with black beans and a side of cecina.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             </Carousel>
             </GridItem>             
       </GridContainer>


       <GridContainer justify="center">      
             <GridItem xs={12} sm={8} md={8}>
             <h2 className={classes.title + " " + classes.textCenter}>
            <br/> Desserts <span className="icon-fingers-scroll-horizontal"></span>
           </h2>            
               <Carousel {...settings1} {...TexturStyle2}>
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tres_Leches} alt="Tres_Leches" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tres_Leches + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TRES LECHES
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Flan_Napolitano} alt="Flan_Napolitano" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Flan_Napolitano + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    FLAN NAPOLITANO
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Churros} alt="Churros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Churros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHURROS
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Cheese_cake} alt="Cheese_cake" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Cheese_cake + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHEESE CAKE
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
             </Carousel>
             </GridItem>             
       </GridContainer>

       <br/>

     </div>
   </div>
     <Footer
       theme="dark"
       content={
         <div>
           <div className={classes.right}>
           Mexpozole Restaurant – Copyright &copy; {1900 + new Date().getYear()}{" "}
           </div>
         </div>
       }
     >
       <GridContainer>
         <GridItem xs={12} sm={6} md={6}>
           <h5>About Us</h5>

           <p>
           Our mission is to give our customers an exquisite homely experience 
           through our values and typical Mexican gastronomy.{" "}
           </p>

           <p>We invite you to enjoy our restaurant's tremendously delicious recipes made from scratch 
                  every day especially for you. Enjoy a delicious breakfast, lunch or dinner at our place.
           </p>
         </GridItem>
         <GridItem xs={12} sm={6} md={6}>
           <h5>Contact Details</h5>
           <div className={classes.socialFeed}>
           <InfoArea
                 className={classes.info}
                 title="Address"
                 description={
                   <p>
                    6871 N. Fry Rd. Suite 112 <br /> Katy, Texas 77449
                   </p>
                 }
                 icon={PinDrop}
                 iconColor="primary"
               />
               <InfoArea
                      className={classes.infoArea2}
                      title="Give us a ring"
                      description={
                        <span>
                          (832) 835 7584 <br />
                        </span>
                      }
                      icon={Phone}
                      iconColor="primary"
                    />
               <InfoArea
                 className={classes.info}
                 title="Hours"
                 description={
                  <p>
                  <span>MON </span> 08:00 – 20:00<br/>
                  <span>TUE </span> 08:00 – 20:00<br/>
                  <span>WED </span> 08:00 – 20:00<br/>
                  <span>THU </span> 08:00 – 20:00<br/>
                  <span>FRI </span> 08:00 – 20:30<br/>
                  <span>SAT </span> 08:00 – 20:30<br/>
                  <span>SUN </span> 08:00 – 20:00  
                </p>
                 }
                 icon={AccessTime}
                 iconColor="primary"
               />
           </div>
         </GridItem>
         <GridItem xs={12} sm={12} md={12}>
         <div className={classes.bigMap}>
       <CustomSkinMap
         googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmDu0h3qmK0X50pZMOzgOenG8J9TeqRLg"
         loadingElement={<div style={{ height: `378px` }} />}
         containerElement={
           <div
             style={{
               height: `378px`,
               borderRadius: "6px",
               overflow: "hidden"
             }}
           />
         }
         mapElement={<div style={{ height: `378px` }} />}
       />
     </div>
         </GridItem>
       </GridContainer>
     </Footer>
   </div>
   );
} 












else {
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    cancelable: false
  };

  return (
    <div>
     <Header
        color="transparent"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "info"
        }}
        {...rest}
      />
  
      <Parallax
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
            <div className={classes.section} id="carousel">
      <div className={classes.container}>
      <GridContainer justify="center">
       <GridItem xs={12} sm={10} md={10}>
           <Card>
             <Carousel {...settings}>
               <div>
                 <img src={Pozole_Rojo} alt="Pozole_Rojo" className="slick-image" />
               </div>
               <div>
                 <img src={Molcajete} alt="Molcajete" className="slick-image" />
               </div>
               <div>
                 <img src={Enchiladas} alt="Enchiladas" className="slick-image" />
               </div>
             </Carousel>
           </Card>
          </GridItem> 
       </GridContainer>
        <h3 className={classes.title + " " + classes.textCenter}>WELCOME TO MEXPOZOLE RESTAURANT</h3>
      </div>
    </div>    
            </GridItem>
          </GridContainer>
          
        </div>
      </div>
      
      <div className={classes.section}>
      <div className={classes.container}>
      <GridContainer>
              
              <GridItem xs={12} sm={12} md={12}> 
              <h2 className={classes.title + " " + classes.textCenter}>
             <br/> Breakfast <span className="icon-fingers-scroll-horizontal"></span>
            </h2>            
                <Carousel {...settings1} {...TexturStyle}>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Kids_Breakfast} alt="Kids_Breakfast" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Kids_Breakfast + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    Pancakes
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Pancakes, served with egg and bacon.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Chilaquiles_Mananeros} alt="Chilaquiles_Mañaneros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Chilaquiles_Mananeros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHILAQUILES MAÑANEROS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Fried tortilla chips, sauteed with your choice of green or red sauce, 
                    topped wiht fresh cheese and sour cream, and two fried eggs. Comes with fried beans.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_Divorciados} alt="Huevos_Divorciados" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_Divorciados + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS DIVORCIADOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Plate with two fried eggs over fried corn tortillas, covered with red and green salsa. 
                    Comes with a side of beans, chopped potatoes and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Chicken_Nuggets} alt="Chicken_Nuggets" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Chicken_Nuggets + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHICKEN NUGGETS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Served with fries
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_al_gusto} alt="Huevos_al_gusto" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_al_gusto + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS AL GUSTO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Two escrambled eggs mix – with your choice of chorizo, potato, bacon, ham, “A la Mexicana”. 
                    Comes with a side of beans and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Molletes} alt="Molletes" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Molletes + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MOLLETES
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    French bread topped with butter, fried beans, melted cheese and pico de gallo.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Huevos_Rancheros} alt="Huevos_Rancheros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Huevos_Rancheros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    HUEVOS RANCHEROS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Plate with two fried eggs over fried corn tortilla covered with salsa ranchera. 
                    Comes with a side of beans, chopped potatoes and corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tacos_mananeros} alt="Tacos_mañaneros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tacos_mananeros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TACOS MAÑANEROS(2)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    (Corn or flour tourtilla)<br/>
                     Two breakfast tacos of your choice: 
                     Egg & chorizo, Egg & potato, Egg & bacon, Egg & ham, Egg “A la mexicana”
                    </p>
                  </CardBody>
                  </Card>
                  </div>
              </Carousel>
              </GridItem>
        </GridContainer> 


        <GridContainer>      
            <GridItem xs={12} sm={12} md={12}>  
             <h2 className={classes.title + " " + classes.textCenter}>
            <br/> Appetizers <span className="icon-fingers-scroll-horizontal"></span>
           </h2>            
               <Carousel {...settings1} {...TexturStyle2}>
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Nachos} alt="Nachos" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Nachos + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    NACHOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Fried corn tortilla chips or totopos covered with cheddar cheese sauce, 
                    topped with guacamole, pico de gallo and sour cream and your choice of beef fajita or chicken.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={CharroBeans} alt="Charro_Beans" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + CharroBeans + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHARRO BEANS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican dish, ingredients include beans, onion, 
                    chili peppers, tomatoes, cilantro, ham, sausage, bacon and chorizo. It is served warm.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Choriqueso} alt="Choriqueso" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Choriqueso + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHORIQUESO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Melted white cheese with chorizo. Served with corn or flour tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Guacamole} alt="Guacamole" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Guacamole + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    GUACAMOLE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Prices according to the market
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             </Carousel>
             </GridItem>             
       </GridContainer>





       <GridContainer>      
            <GridItem xs={12} sm={12} md={12}>  
             <h2 className={classes.title + " " + classes.textCenter}>
            <br/> Entrees <span className="icon-fingers-scroll-horizontal"></span>
           </h2>            
               <Carousel {...settings1} {...TexturStyle}>
               <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pozole_Rojo} alt="Pozole_Rojo " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pozole_Rojo + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POZOLE ROJO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican red Soup with pork meat and hominy. 
                    Garnished with shredded lettuce, radish and crispy pork skin on a side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pozole_Verde} alt="Pozole_Verde" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pozole_Verde + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POZOLE VERDE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican Green Soup with chicken meat and hominy. 
                    Garnished with shredded lettuce, radish and crispy pork skin on a side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Menudo} alt="Menudo" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Menudo + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MENUDO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Traditional Mexican soup made with cow’s stomach. Also known as “Pancita”, 
                    Served with flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pollo_a_la_Plancha } alt="TACOS_POTOSINOS" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pollo_a_la_Plancha + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TACOS POTOSINOS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Soft fried corn tortilla with red sauce, filled with fresh cheese, salad, and beef fajita on the side.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tampiquena } alt="Tampiqueña " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tampiquena  + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TAMPIQUEÑA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Strip of the roasted beef over grilled onions and bell pepper. 
                    Served with a red enchilada filled with white cheese, black beans, 
                    rice, pico de gallo and your choice of flour or homemade corn tortillas
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Enchiladas} alt="Enchiladas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Enchiladas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ENCHILADAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Soft corn tortillas filled with chicken, 
                    topped with mole sauce and sesame seeds. 
                    Served with a side of fried beans and rice.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Flautas} alt="Flautas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Flautas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    FLAUTAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Rolled fried tacos with your choice of chicken or beef, topped with 
                    lettuce, tomato, fresh cheese and sour cream. Served with a side of fried beans and rice.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Pollo_ala_Plancha} alt="Pollo_ala_Plancha" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Pollo_ala_Plancha + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    POLLO A LA PLANCHA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Grilled chicken breast served with lettuce, tomatoes, pico de gallo, bell pepper, 
                    rice and charros beans. Served with flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Quesadillas} alt="Orden_De_Quesadillas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Quesadillas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE QUESADILLAS (2)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Corn or flour tortillas with mozzarella cheese and meat of your choice:
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajta<br/>
                    Cochinita pibil,<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Sopes} alt="Orden_De_Sopes" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Sopes + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE SOPES (3)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Thick homemade corn tortilla, slightly fried, 
                    topped with black beans, lettuce, tomato, fresh cheese, sour cream, and meat of your choice:
                    Trompo<br/>
                     Beef fajita<br/>
                     Chicken fajta<br/>
                     Cochinita pibil<br/>
                     Chorizo<br/>
                     Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Tortas} alt="Tortas" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tortas + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TORTAS
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Mexican sandwich in a bolillo bread, topped with refried beans, beans, lettuce, 
                    tomato, and sour cream, and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibl<br/>
                    Chorizo<br/>
                    Carnitas<br/>
                    Cubana (comes with pork meat, ham and<br/>
                    mozzarella cheese)
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Quesadilla_Grande} alt="Quesadilla_Grande" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Quesadilla_Grande + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    QUESADILLA GRANDE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Comes with refriend beans, rice, pico de gallo, and lettuce on the side. 
                    Large flour tortilla filled with mozzarella cheese and meat of your choice:<br/>                    
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajta<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Burrito} alt="Burrito " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Burrito + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    BURRITO
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Large flour tortilla filled with refried beans, 
                    rice, lettuce, tomato, fresh cheese, sour cream, and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Orden_De_Tacos} alt="Orden_De_Tacos " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Orden_De_Tacos + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ORDEN DE TACOS (4)
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Four corn tortillas topped with grilled onion, cilantro, pickled carrots on the side, 
                    and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Gordita} alt="Gordita " />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Gordita + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    GORDITA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Thick homemade tortilla filled with mozzarella cheese and meat of your choice:<br/>
                    Trompo<br/>
                    Beef fajita<br/>
                    Chicken fajita<br/>
                    Cochinita pibil<br/>
                    Chorizo<br/>
                    Carnitas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Molcajete} alt="Molcajete" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Molcajete + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    MOLCAJETE
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Grilled beef and chicken fajitas, and mexican sausage served with grilled onion, mexican cheese, 
                    cactus and bell pepper in a stone bowl. 
                    Comes with charro beans, rice and pico de gallo on the side. Includes flour or homemade corn tortillas.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Entomatadas_Con_Cecina} alt="Entomatadas_Con_Cecina" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Entomatadas_Con_Cecina + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    ENTOMATADAS CON CECINA
                    </h4>
                    <p className={classes.description1 + " " + classes.textCenter}>
                    Three homemade tortillas dressed in ranchera sauce, 
                    topped with fresh cheese, served with black beans and a side of cecina.
                    </p>
                  </CardBody>
                  </Card>
                  </div>
             </Carousel>
             </GridItem>             
       </GridContainer>


       <GridContainer>      
            <GridItem xs={12} sm={12} md={12}>  
             <h2 className={classes.title + " " + classes.textCenter}>
           <br/> Desserts <span className="icon-fingers-scroll-horizontal"></span>
           </h2>            
               <Carousel {...settings1} {...TexturStyle2}>
               <div> 
                  <Card blog>
                  <CardHeader image>
                      <img src={Tres_Leches} alt="Tres_Leches" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Tres_Leches + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    TRES LECHES
                    </h4>
                  </CardBody>
                  </Card>
                  
                   </div>
                 
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Flan_Napolitano} alt="Flan_Napolitano" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Flan_Napolitano + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    FLAN NAPOLITANO
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
                  <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Churros} alt="Churros" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Churros + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHURROS
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
                <div>
                  <Card blog>
                  <CardHeader image>
                      <img src={Cheese_cake} alt="Cheese_cake" />
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + Cheese_cake + ")",
                        opacity: "1"
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle + " " + classes.textCenter}>        
                    CHEESE CAKE
                    </h4>
                  </CardBody>
                  </Card>
                  </div>
             </Carousel>
             </GridItem>             
       </GridContainer>


      </div>
    </div>
    <br/>
      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.right}>
            Mexpozole Restaurant – Copyright &copy; {1900 + new Date().getYear()}{" "}
            </div>
          </div>
        }
      >
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <h5>About Us</h5>

            <p>
            Our mission is to give our customers an exquisite homely experience 
            through our values and typical Mexican gastronomy.{" "}
            </p>

            <p>We invite you to enjoy our restaurant's tremendously delicious recipes made from scratch 
                   every day especially for you. Enjoy a delicious breakfast, lunch or dinner at our place.
            </p>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <h5>Contact Details</h5>
            <div className={classes.socialFeed}>
            <InfoArea
                  className={classes.info}
                  title="Address"
                  description={
                    <p>
                     6871 N. Fry Rd. Suite 112 <br /> Katy, Texas 77449
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="primary"
                />
                <InfoArea
                      className={classes.infoArea2}
                      title="Give us a ring"
                      description={
                        <span>
                          (832) 835 7584 <br />
                        </span>
                      }
                      icon={Phone}
                      iconColor="primary"
                    />
                <InfoArea
                  className={classes.info}
                  title="Hours"
                  description={
                    <p>
                      <span>MON </span> 08:00 – 20:00<br/>
                      <span>TUE </span> 08:00 – 20:00<br/>
                      <span>WED </span> 08:00 – 20:00<br/>
                      <span>THU </span> 08:00 – 20:00<br/>
                      <span>FRI </span> 08:00 – 20:30<br/>
                      <span>SAT </span> 08:00 – 20:30<br/>
                      <span>SUN </span> 08:00 – 20:00  
                    </p>
                  }
                  icon={AccessTime}
                  iconColor="primary"
                />
            </div>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
          <div className={classes.bigMap}>
        <CustomSkinMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmDu0h3qmK0X50pZMOzgOenG8J9TeqRLg"
          loadingElement={<div style={{ height: `378px` }} />}
          containerElement={
            <div
              style={{
                height: `378px`,
                borderRadius: "6px",
                overflow: "hidden"
              }}
            />
          }
          mapElement={<div style={{ height: `378px` }} />}
        />
      </div>
          </GridItem>
        </GridContainer>
      </Footer>
    </div>
  );
}
}
